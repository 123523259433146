
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  /* overflow: hidden; */
  font-family: 'Varela Round', sans-serif !important;
  /* font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex-grow: 1;
  overflow: auto;
  /* Allow scroll on root */
}

.page-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  height: 100%;
  /* background: radial-gradient(at left top, #4C5EE0, #F3FAFF); */
  /* background: radial-gradient(at right bottom, #111316, #8965EA, #111316); */
}

.content-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* border-radius: 20px; */
  /* margin: 0 20px; */
  /* background: rgb(17, 18, 22);
  background: linear-gradient(161deg, rgba(17, 18, 22, 1) 0%, rgba(137, 101, 234, 1) 99%); */
  /* background: #111316;
  background: radial-gradient(at left top, #111316, #8965EA); */
  /* background: radial-gradient(circle farthest-side at 50% -30%,#151517 59%,#004fdb 75%,#64afff 83%,#fff 93%); */
}
